import { acceptHMRUpdate } from 'pinia'

const stores = {
  system: () => import('./system').then(m => m.useSystemStore()),
  user: () => import('./user').then(m => m.useUserStore()),
  auth: () => import('./auth').then(m => m.useAuthStore())
}

// Simplified store caching
const cachedStores = new Map()

export const storeImports = {
  system: async () => {
    if (cachedStores.has('system')) {
      return cachedStores.get('system')
    }
    
    const store = await stores.system()
    cachedStores.set('system', store)
    return store
  },
  user: async () => {
    if (cachedStores.has('user')) {
      return cachedStores.get('user')
    }
    
    const store = await stores.user()
    cachedStores.set('user', store)
    return store
  },
  auth: async () => {
    if (cachedStores.has('auth')) {
      return cachedStores.get('auth')
    }
    
    const store = await stores.auth()
    cachedStores.set('auth', store)
    return store
  }
}

// Clear cache and re-initialize stores on HMR
if (import.meta.hot) {
  import.meta.hot.accept(['./user.js', './system.js', './auth.js'], (modules) => {
    cachedStores.clear() // Clear cache on HMR update
    modules?.forEach(module => {
      if (module?.useUserStore) acceptHMRUpdate(module.useUserStore, import.meta.hot)
      if (module?.useSystemStore) acceptHMRUpdate(module.useSystemStore, import.meta.hot)
      if (module?.useAuthStore) acceptHMRUpdate(module.useAuthStore, import.meta.hot)
    })
  })
}
