import { defineStore, acceptHMRUpdate } from 'pinia'
import { ref } from 'vue'
import authenticatedApiClient from '@/services/authenticatedApiClient'
import backendApiClient from '@/services/backendApiClient'

export const useUserStore = defineStore('user', {
  state: () => ({
    token: ref(''),
    user: ref({}),
    intended: ref('')
  }),
  persist: {
    storage: localStorage,
    paths: ['token', 'user', 'intended']
  },
  getters: {
    isAuthenticated: state => Boolean(state.token),
    authUser: state => state.user
  },
  actions: {
    async fetchUser() {
      const response = await authenticatedApiClient.get('/api/v1/resources/users/authenticated')
      this.user = response.data.data
    },

    async signIn(formData) {
      // Use the same endpoint for both regular and Azure login
      const response = await backendApiClient.post('/api/v1/auth/login', formData)
      this.token = response.data.token
      this.user = response.data.user
    },

    async clearUserData() {
      await Promise.all([
        window.localStorage.clear(), 
        window.sessionStorage.clear()
      ])
      this.token = ''
      this.user = {}
      this.intended = ''
    },

    async updateUserData(data) {
      const filteredData = Object.fromEntries(
        Object.entries(data).filter(([_, v]) => v !== '')
      )
      
      await authenticatedApiClient.patch('/api/v1/resources/users/authenticated', filteredData)
      await this.fetchUser()
    },

    // @TODO: Consolidated User update Requests (One request for all updates)
    async updateProfile(formData) {
      await this.updateUserData('/api/v1/resources/users/authenticated', formData)
    },
    
    async updateBusinessLocation(formData) {
      await this.updateUserData('/api/v1/resources/users/business-location', formData)
    },
    
    async updateUserSettings(formData) {
      await this.updateUserData('/api/v1/resources/users/settings', formData)
    },
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
