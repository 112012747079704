import { defineStore } from 'pinia'

export const useSearchStore = defineStore('search', {
  state: () => ({
    resourceModels: [],
    searchPreferences: []
  }),
  persist: true,
  getters: {
    getSearchConfiguration: state => (tableName) => {
      return state.resourceModels.find(resourceModel => resourceModel.tableName === tableName)?.data
    },
    getSearchPreferences: state => (tableName) => {
      return state.searchPreferences.find(
        searchPreference => searchPreference.tableName === tableName
      )?.data
    }
  },
  actions: {
    updateSearchConfiguration(tableName, config) {
      const index = this.resourceModels.findIndex(model => model.tableName === tableName)
      if (index !== -1) {
        this.resourceModels[index] = { tableName, data: config }
      }
      else {
        this.resourceModels.push({ tableName, data: config })
      }
    },
    updateSearchPreferences(tableName, preferences) {
      const index = this.searchPreferences.findIndex(pref => pref.tableName === tableName)
      if (index !== -1) {
        this.searchPreferences[index] = { tableName, data: preferences }
      }
      else {
        this.searchPreferences.push({ tableName, data: preferences })
      }
    }
  }
})
