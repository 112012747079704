import { defineStore, acceptHMRUpdate } from 'pinia'
import { getUserStore } from './storeBootstrap'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    loading: false,
    errors: []
  }),

  actions: {
    clearErrors() {
      this.errors = []
    },

    setError(error) {
      this.errors.push({
        message: error.response?.data?.message || error.message || 'An error occurred'
      })
    },

    async initialize() {
      const userStore = await getUserStore()
      if (userStore.isAuthenticated) {
        try {
          await userStore.fetchUser()
        } catch (error) {
          this.setError(error)
          await userStore.clearUserData()
        }
      }
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}