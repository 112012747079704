import { defineStore, acceptHMRUpdate } from 'pinia'
import authenticatedApiClient from '@/services/authenticatedApiClient'

// Track ongoing request to prevent duplicate API calls
let fetchPromise = null

export const useSystemStore = defineStore('system', {
  state: () => ({
    resourceModels: [],
    routes: [],
    permissions: [],
    pusherSettings: {
      appKey: null,
      cluster: null,
      appId: null,
      // Add other Pusher settings you might need
    },
    timestamp: null,
    isLoading: false,
    retryCount: 0
  }),
  persist: {
    storage: sessionStorage,
    key: 'system_store',
    afterHydrate: (ctx) => {
      const oneHour = 3600000
      if (Date.now() - ctx.store.timestamp > oneHour) {
        ctx.store.$reset()
      }
      else {
        // eslint-disable-next-line no-console
        console.log(`just hydrated '${ctx.store.$id}'`, ctx.store)
      }
    }
  },
  getters: {
    indexableResourceModels: state => state.resourceModels.filter(model => model.crud_index),
    resourceRoutes: state => state.routes.filter(route => route?.name?.startsWith('api.resources')),
    dropdownRoutes: state => state.routes.filter(route => route?.name?.startsWith('api.dropdown'))
  },
  actions: {
    fetchSystemData() {
      // If data is already loaded, just return a resolved promise with the data
      if (!this.isSystemDataEmpty()) {
        // eslint-disable-next-line no-console
        console.log('[SystemStore] System data already loaded, returning cached data');
        return Promise.resolve({
          resourceModels: this.resourceModels,
          routes: this.routes,
          permissions: this.permissions,
          settings: { pusher: this.pusherSettings }
        });
      }
      
      // If there's already a fetch in progress, return that promise
      if (fetchPromise) {
        // eslint-disable-next-line no-console
        console.log('[SystemStore] System data fetch already in progress, returning existing promise');
        return fetchPromise;
      }
      
      this.isLoading = true;
      
      // eslint-disable-next-line no-console
      console.log('[SystemStore] Fetching system data from API');
      
      // Create a new fetch promise
      fetchPromise = (async () => {
        try {
          const response = await authenticatedApiClient.get('/api/v1/system/data');
          const { resource_models: resourceModels, routes, permissions, settings } = response.data;
          
          // eslint-disable-next-line no-console
          console.log('[SystemStore] System data fetched successfully:',
            `${resourceModels?.length || 0} models, ${routes?.length || 0} routes`);
          
          this.resourceModels = resourceModels;
          this.routes = routes;
          this.permissions = permissions;
          
          // Store Pusher settings if available in the response
          if (settings?.pusher) {
            this.pusherSettings = {
              appKey: settings.pusher.app_key,
              cluster: settings.pusher.cluster,
              appId: settings.pusher.app_id,
              // Map other Pusher settings as needed
            };
          }
          
          this.timestamp = Date.now();
          this.retryCount = 0;
          
          return { resourceModels, routes, permissions, settings };
        }
        catch (error) {
          // eslint-disable-next-line no-console
          console.error('[SystemStore] Failed to fetch system data:', error);
          if (this.retryCount < 3) {
            this.retryCount++;
            // Exponential backoff
            const backoffTime = Math.pow(2, this.retryCount) * 1000;
            // eslint-disable-next-line no-console
            console.log(`[SystemStore] Retrying in ${backoffTime}ms (attempt ${this.retryCount}/3)`);
            await new Promise(resolve => setTimeout(resolve, backoffTime));
            
            // Clear the current promise to allow retry
            fetchPromise = null;
            
            // Retry the fetch
            return this.fetchSystemData();
          }
          throw error;
        }
        finally {
          this.isLoading = false;
          // Clear the promise reference after a short delay
          // This prevents immediate duplicate calls while allowing future calls
          setTimeout(() => {
            fetchPromise = null;
          }, 100);
        }
      })();
      
      return fetchPromise;
    },
    
    isSystemDataEmpty() {
      return (
        !this.resourceModels?.length ||
        !this.routes?.length ||
        !this.permissions?.length ||
        !this.pusherSettings?.appKey
      )
    },
    
    // Force refresh system data even if already loaded
    async refreshSystemData() {
      // Reset state
      this.resourceModels = []
      this.routes = []
      this.permissions = []
      this.pusherSettings = {
        appKey: null,
        cluster: null,
        appId: null
      }
      
      // Clear any existing fetch promise
      fetchPromise = null
      
      // Fetch fresh data
      return await this.fetchSystemData()
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSystemStore, import.meta.hot))
}
