import { defineStore } from 'pinia'
import authenticatedApiClient from '@/services/authenticatedApiClient'
import { getCurrentInstance } from 'vue'
import { debounce } from 'lodash'

export const useUserTasksStore = defineStore('userTasks', {
  state: () => ({
    tasks: [],
    loading: false,
    lastUpdateTime: 0,
    isFetchingTasks: false, // Track if a fetch is already in progress
    isInitialized: false, // Track if the store has been initialized
    formData: {
      keyword: '',
      date_range: getDefaultDateRange(),
      order_stage_types: [],
      order_stage_start_date_range: null,
      order_stage_due_date_range: null,
      order_stage_lead_time_first_proof_date_range: null,
      order_stage_lead_time_correction_date_range: null,
      order_stage_lead_time_final_delivery_date_range: null,
      task_categories: [],
      task_types: [],
      task_cycles: [],
      task_cycle_reasons: [],
      task_start_date_range: null,
      task_created_by_users: [],
      task_planning_date_range: null,
      include_finished: false
    }
  }),

  actions: {
    // Initialize the store and ensure we only fetch once
    async initialize(options = {}) {
      // If already initialized and the cache is still fresh, skip fetch
      if (this.isInitialized && this.isCacheFresh()) {
        if (import.meta.env.DEV) {
          // console.log('[Tasks] Using cached data, skipping fetch')
        }
        return await Promise.resolve({ data: this.tasks })
      }

      // If not initialized or cache expired, fetch tasks
      return this.fetchTasksImmediate({
        ...options,
        force: true,
        source: 'initialization'
      }).then(response => {
        this.isInitialized = true
        return response
      })
    },

    // Check if our cache is still fresh (less than 30 seconds old)
    isCacheFresh() {
      const CACHE_TTL = 30000 // 30 seconds in milliseconds
      return Date.now() - this.lastUpdateTime < CACHE_TTL
    },

    // Update a specific filter field
    updateFilter(key, value) {
      this.formData[key] = value
      return this.fetchTasks({ source: 'filter-change', force: true })
    },

    // Reset filters to default
    resetFilters() {
      this.formData = {
        keyword: '',
        date_range: getDefaultDateRange(),
        order_stage_types: [],
        order_stage_start_date_range: null,
        order_stage_due_date_range: null,
        order_stage_lead_time_first_proof_date_range: null,
        order_stage_lead_time_correction_date_range: null,
        order_stage_lead_time_final_delivery_date_range: null,
        task_categories: [],
        task_types: [],
        task_cycles: [],
        task_cycle_reasons: [],
        task_start_date_range: null,
        task_created_by_users: [],
        task_planning_date_range: null,
        include_finished: false
      }
      return this.fetchTasks({ source: 'filter-reset', force: true })
    },

    // Non-debounced implementation that always returns a promise
    async fetchTasksImmediate(options = {}) {
      const {
        overrideFilters = null,
        backgroundRefresh = false,
        force = false,
        _source = 'unknown'
      } = options

      // If we're already fetching, don't start another fetch
      if (this.isFetchingTasks) {
        if (import.meta.env.DEV) {
          // console.log(`[Tasks] Fetch already in progress, returning existing promise (source: ${source})`)
        }
        return Promise.resolve({ data: this.tasks })
      }

      // Check cache freshness unless forced
      if (!force && this.isInitialized && this.isCacheFresh()) {
        if (import.meta.env.DEV) {
          return Promise.resolve({ data: this.tasks })
        }
        return Promise.resolve({ data: this.tasks })
      }

      // Don't set loading state for background refreshes
      if (!backgroundRefresh) {
        this.loading = true
      }

      // Mark that we're fetching tasks
      this.isFetchingTasks = true

      // Allow for temporarily overriding filters for specific use cases
      const filterData = overrideFilters || this.formData
      
      try {
        const response = await authenticatedApiClient.post('/api/v1/resources/tasks/user-tasks', filterData)
        this.tasks = response.data
        this.lastUpdateTime = Date.now()
        this.isInitialized = true
        return response
      } catch (error) {
        // Re-throw the error to be handled by the calling code
        return Promise.reject(error)
      } finally {
        this.isFetchingTasks = false
        if (!backgroundRefresh) {
          this.loading = false
        }
      }
    },

    // Get tasks using current filters with debounce
    fetchTasks(options = {}) {
      return this.debouncedFetchTasks(options)
    },

    // The actual debounced implementation (private method)
    debouncedFetchTasks: debounce(function(options = {}) {
      return this.fetchTasksImmediate(options)
    }, 300),

    // Method to handle task updates with source tracking
    handleTaskUpdate(source = 'unknown', options = {}) {
      const { force = false, skipFetch = false } = options
      
      // If cache is fresh and not forced, skip the update
      if (!force && this.isInitialized && this.isCacheFresh()) {
        return Promise.resolve({ data: this.tasks })
      }
      
      // Create an event object to pass to mitt
      const eventData = { source, ...options }
      
      // Emit the tasks.updated event with source information
      const mitt = getCurrentInstance()?.appContext.config.globalProperties.$mitt
      if (mitt) {
        mitt.emit('tasks.updated', eventData)
      }
      
      // Skip fetch if requested
      if (skipFetch) {
        return Promise.resolve({ data: this.tasks })
      }
      
      // Fetch tasks, in background unless it's a local/manual update
      return this.fetchTasks({
        source,
        backgroundRefresh: source !== 'local' && source !== 'manual',
        force
      }).catch(error => {
        throw new Error(`Failed to refresh tasks after update from ${source}: ${error.message}`)
      })
    },

    // Explicitly invalidate the cache when needed
    invalidateCache() {
      this.lastUpdateTime = 0
    }
  }
})

// Helper function to get default date range (today and tomorrow)
function getDefaultDateRange() {
  const startDate = new Date()
  const endDate = new Date()
  endDate.setDate(endDate.getDate() + 1)
  
  const formatDate = date => date.toISOString().split('T')[0]
  return `${formatDate(startDate)} to ${formatDate(endDate)}`
}