import { defineStore } from 'pinia'

export const useShowStore = defineStore('show', {
  state: () => ({
    preferences: [
      // 8: {
      //     sidebar: false,
      //     header: false,
      // },
      // 9: {
      //     sidebar: false,
      //     header: false,
      // },
    ]
  }),
  // make persistant based on developoment or production
  // persist: process.env.NODE_ENV !== 'development',
  persist: true
})
