import axios from 'axios'
import { storeImports } from '@/stores/storeImports'

const baseURL = import.meta.env.VITE_BACKEND_BASE_URL

const authenticatedApiClient = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  withCredentials: true
})

authenticatedApiClient.interceptors.request.use(
  async (config) => {
    const userStore = await storeImports.user()
    const token = userStore.token
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => Promise.reject(error)
)

authenticatedApiClient.interceptors.response.use(
  response => response,
  async (error) => {
    const userStore = await storeImports.user()
    const status = error.response?.status
    const originalRequest = error.config

    if (status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      await userStore.clearUserData()
      window.location.href = '/login'
      return Promise.reject(error)
    }

    if (status === 429) {
      const retryAfter = error.response.headers['retry-after']
      if (retryAfter) {
        await new Promise(resolve => setTimeout(resolve, retryAfter * 1000))
        return authenticatedApiClient(originalRequest)
      }
    }

    return Promise.reject(error)
  }
)

export default authenticatedApiClient
